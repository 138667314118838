<template>
  <div class="locale-changer-wrapper">
    <v-menu
      offset-y
      :nudge-right="35"
      :nudge-bottom="5"
      min-width="175"
      left
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="d-flex align-center"
          v-on="on"
        >
          <p
            v-if="currentLang.beta"
            class="beta-text m-0 text-yellow mr-1 align-self-center"
          >
            BETA
          </p>
          <img :src="currentLang.flag" :alt="currentLang.title" width="20px">
          <div class="align-self-end" style="margin-left: 5px;">
            <span class="locale-changer-title">{{ currentLang.title }}</span>
            <v-icon>
              {{ icons.mdiChevronDown }}
            </v-icon>
          </div>
        </div>
      </template>
      <v-list dense>
        <v-list-item-group
          :value="$i18n.locale"
          @change="changeLocale"
        >
          <v-list-item
            v-for="locale in locales"
            :key="locale.key"
            :value="locale.key"
          >
            <v-list-item-title style="color: #292f4d80; font-size: 14px;">
              {{ locale.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiChevronDown, mdiEarth } from '@mdi/js'
import _find from 'lodash/find'
import { localeHeaderName } from '@/options/localeOptions'
import { localize } from 'vee-validate'

export default {
  name: 'LocaleChanger',

  data () {
    return {
      icons: { mdiChevronDown, mdiEarth }
    }
  },

  computed: {
    ...mapGetters('user', ['isLoggedIn']),

    currentLang () {
      return _find(this.locales, ['key', this.$i18n.locale])
    },

    locales () {
      return [
        { title: 'English (US)', key: 'en', flag: require('@/assets/img/language-icons/en.png'), beta: false },
        { title: 'Español', key: 'es', flag: require('@/assets/img/language-icons/es.png'), beta: false },
        { title: 'Português', key: 'pt', flag: require('@/assets/img/language-icons/pt.png'), beta: false },
        { title: 'Français', key: 'fr', flag: require('@/assets/img/language-icons/fr.png'), beta: false },
        { title: 'Deutsch', key: 'de', flag: require('@/assets/img/language-icons/de.png'), beta: false },
        { title: 'Italiano', key: 'it', flag: require('@/assets/img/language-icons/it.png'), beta: false },
        { title: 'Polski', key: 'pl', flag: require('@/assets/img/language-icons/pl.png'), beta: false },
        { title: 'Nederlands', key: 'nl', flag: require('@/assets/img/language-icons/nl.png'), beta: false },
        { title: 'Srpski', key: 'sr', flag: require('@/assets/img/language-icons/sr.png'), beta: false }
      ]
    }
  },

  methods: {
    ...mapActions('user', ['refreshUserData', 'getCurrentUser']),

    changeLocale (locale) {
      if (!locale) return
      this.$http.defaults.headers.common[localeHeaderName] = locale

      if (this.isLoggedIn) {
        this.refreshUserData()
        this.$intercom.update({
          language_override: locale
        })
      }

      // this.$vuetify.lang = locale
      this.$i18n.locale = locale
      // this.$moment.locale(locale)

      localStorage.setItem('lang', locale)
      localize(locale)
    }
  }

}
</script>

<style scoped lang="scss">
.locale-changer-wrapper {
  color: #292f4d80;

  .ib-icon-world {
    font-size: 18px;
  }

  .locale-changer-title {
    font-size: 14px;
  }

  .beta-text {
    font-size: 10px;
    font-weight: 700;
  }
}
</style>

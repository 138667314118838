<template>

  <!-- Carousel -->
  <el-carousel indicator-position="inside" class="el-carousel" arrow="never" :interval="10000">

    <!-- Logo -->
    <a class="logo" href="https://ideabuddy.com" target="_blank"><img src="../assets/img/logos/ideaBuddy-white.svg"></a>
    <!-- /Logo -->

    <!-- Carousel Item -->
    <el-carousel-item
      v-for="item in carouselItems"
      :key="item.id"
      :style="'background: linear-gradient(rgba(73, 73, 73, 0), rgba(0,0,0,0.3)), url(' + item.imageUrl + ');'"
    >

      <!-- Content -->
      <div class="carousel-inner-content">

        <!-- Quote Image -->
        <img class="carousel-icon" src="../assets/img/icons/left-quote.svg" alt="">
        <!-- /Quote Image -->

        <!-- Text -->
        <h3>{{ item.itemQuoteText }}</h3>
        <!-- /Text -->

        <!-- Author -->
        <p>{{ item.itemQuoteAuthor }}</p>
        <!-- /Author -->

      </div>
      <!-- /Content -->

    </el-carousel-item>
    <!-- /Carousel Item -->

  </el-carousel>
  <!-- /Carousel -->

</template>

<script>
export default {
  name: 'Carousel',

  props: {
    carouselItems: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>

  <!-- Reset Password -->
  <div id="signup">
    <div>
      <el-row class="d-block d-xl-flex overflow-scroll">

        <!-- Carousel -->
        <el-col :xl="12" class="d-none d-xl-block">
          <div class="grid-content">
            <carousel :carousel-items="carouselItemsSignup" />
          </div>
        </el-col>
        <!-- /Carousel -->

        <!-- Sign Up Form -->
        <el-col :xl="12" class="form-col d-flex flex-column">
          <v-app>
            <div class="form-container align-items-center">

              <!-- Logo Mobile -->
              <img class="logo-mobile my-4 d-xl-none" src="@/assets/img/logos/ideaBuddy-color.svg" alt="">
              <!-- /Logo Mobile -->

              <locale-changer class="mb-3" />

              <!-- Form Wrapper -->
              <div class="form-wrapper">

                <!-- Form Inner Wrapper -->
                <div class="form-inner-wrapper">

                  <!-- Form -->
                  <el-form ref="resetPassword" :model="resetPassword" :rules="rules">

                    <!-- Title -->
                    <h1>{{ $t('auth.forgotPassword.resetYourPassword') }}</h1>
                    <!-- /Title -->

                    <!-- Subtitle -->
                    <p class="is-text-small">
                      {{ $t('auth.forgotPassword.enterNewPassword') }}
                    </p>
                    <!-- /Subtitle -->

                    <!-- Alert Error -->
                    <transition name="fade">
                      <div v-if="showErrorAlertLogin" style="margin-bottom: 20px;">
                        <el-alert
                          :title="errorMessageLogin"
                          type="error"
                          show-icon
                          :closable="false"
                        />
                      </div>
                    </transition>
                    <!-- /Alert Error -->

                    <!-- Password -->
                    <el-form-item
                      class="input-inside-label" :label="$t('auth.global.password')" prop="password"
                      :class="{'focused': focus.password}"
                    >
                      <el-input
                        v-model="resetPassword.password"
                        class="default password"
                        placeholder=" "
                        :show-password="true"
                        @input="onInput()"
                        @focus="toggleFocus('password')"
                        @blur="toggleFocus('password')"
                      />
                    </el-form-item>
                    <!-- /Password -->

                    <!-- Repeat Password -->
                    <el-form-item
                      class="input-inside-label" :label="$t('auth.forgotPassword.repeatPassword')" prop="repeatPassword"
                      :class="{'focused': focus.repeatPassword}"
                    >
                      <el-input
                        v-model="resetPassword.repeatPassword"
                        class="default password"
                        placeholder=" "
                        :show-password="true"
                        @input="onInput()"
                        @focus="toggleFocus('repeatPassword')"
                        @blur="toggleFocus('repeatPassword')"
                      />
                    </el-form-item>
                  <!-- /Repeat Password -->
                  </el-form>
                <!-- /Form -->

                </div>
                <!-- /Form Inner Wrapper -->

                <!-- Sign Up Button -->
                <el-button class="full" :loading="loadingOnResetPassword" @click="onResetPassword">
                  {{ $t('auth.forgotPassword.buttonResetPassword') }}
                </el-button>
              <!-- /Sign Up Button -->

              </div>
            <!-- /Form Wrapper -->

            </div>
          </v-app>
        </el-col>
        <!-- /Sign Up Form -->

      </el-row>
    </div>
  </div>
  <!-- /Sign Up -->

</template>

<script>
import Carousel from '../../components/Carousel'
import LocaleChanger from '@/components/LocaleChanger.vue'

export default {
  name: 'ResetPassword',

  components: {
    LocaleChanger,
    Carousel
  },

  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        return callback(new Error(this.$t('validations.passwordMustBe')))
      }
      this.resetPassword.password = value
      callback()
    }

    const validateRepeatPassword = (rule, value, callback) => {
      if (this.resetPassword.password !== value) {
        return callback(new Error(this.$t('validations.passwordsMustMatch')))
      }
      callback()
    }

    return {
      resetPassword: {
        password: '',
        repeatPassword: ''
      },
      focus: {
        password: false,
        repeatPassword: false
      },
      loadingOnResetPassword: false,
      showErrorAlertLogin: false,
      errorMessageLogin: '',
      rules: {
        password: [
          { required: true, message: this.$t('validations.enterNewPassword'), trigger: 'submit' },
          { validator: validatePassword, trigger: 'submit' }
        ],
        repeatPassword: [
          { required: true, message: this.$t('validations.reEnterNewPassword'), trigger: 'submit' },
          { validator: validateRepeatPassword, trigger: 'submit' }
        ]
      },
      token: '',
      email: ''
    }
  },

  computed: {
    carouselItemsSignup () {
      return [
        {
          id: 1,
          imageUrl: require('@/assets/img/photos/login-new-square-4.png'),
          itemQuoteText: this.$t('auth.generalQuotes.successUsuallyComes'),
          itemQuoteAuthor: 'Henry David Thoreau'
        },
        {
          id: 2,
          imageUrl: require('@/assets/img/photos/signup-new-square-1.png'),
          itemQuoteText: this.$t('auth.generalQuotes.dontLetTheFear'),
          itemQuoteAuthor: 'Robert Kiyosaki'
        },
        {
          id: 3,
          imageUrl: require('@/assets/img/photos/signup-new-square-2.png'),
          itemQuoteText: this.$t('auth.generalQuotes.inOrderToSucceed'),
          itemQuoteAuthor: 'Nikos Kazantzakis'
        },
        {
          id: 4,
          imageUrl: require('@/assets/img/photos/signup-new-square-3.png'),
          itemQuoteText: this.$t('auth.generalQuotes.itDoesNotMatter'),
          itemQuoteAuthor: 'Confucius'
        }
      ]
    }
  },

  created () {
    this.email = this.$route.query.email
    this.token = this.$route.query.token
  },

  methods: {
    checkInputLength (input) {
      this.focus[input] = !!this.resetPassword[input]
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.resetPassword[input]) {
        this.focus[input] = true
      }
    },

    onInput () {
      this.showErrorAlertLogin = false
      this.$refs.resetPassword.clearValidate()
    },

    onResetPassword () {
      this.loadingOnResetPassword = true
      this.$refs.resetPassword.validate((valid) => {
        if (valid) {
          const data = {
            token: this.token,
            email: this.email,
            password: this.resetPassword.password
          }
          this.$http.post('authentication/reset-password', data)
            .then((response) => {
              if (response.status === 200) {
                this.$router.push({ name: 'login' })
              }
            })
            .catch(e => {
              this.loadingOnResetPassword = false

              if (typeof e.response !== 'undefined' && e.response.status === 401) {
                this.errorMessageLogin = this.$t('validations.invalidOrExpiredToken')
                this.showErrorAlertLogin = true

                return
              }

              this.errorMessageLogin = this.$t('validations.sorrySomethingWentWrong')
              this.showErrorAlertLogin = true
            })
        } else {
          this.loadingOnResetPassword = false

          return false
        }
      })
    }
  }
}
</script>
